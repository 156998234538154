<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('module_settings.module_settings')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>

            <div class="card-body">
                <div class="form-group row">


                    <div class="col-lg-6 mb-5">
                        <label>{{$t('module_settings.module_name')}}</label>
                        <select name="module_id" v-model="data.module_id" :disabled="true" class="custom-select" :class="validation && validation.module_id ? 'is-invalid' : ''">
                            <option v-for="row in module_settings" :key="row.id" :value="row.id">{{row.module_name}}</option>
                        </select>
                        <span v-if="validation && validation.module_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.module_id[0] }}
                                    </span>
                    </div>

                    <div class="col-lg-6 mb-5">
                        <label>{{$t('module_settings.title')}}<span class="text-danger">*</span></label>
                        <input type="text" v-model="data.title" class="form-control" :class="validation && validation.title ? 'is-invalid' : ''" :placeholder="$t('module_settings.title')"/>
                        <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.title[0] }}
                                    </span>
                    </div>
                    <div class="col-lg-6 mb-5">
                        <label>{{$t('module_settings.summery')}}</label>
                        <textarea v-model="data.summery" class="form-control" :class="validation && validation.summery ? 'is-invalid' : ''" :placeholder="$t('module_settings.summery')"></textarea>
                        <span v-if="validation && validation.summery" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.summery[0] }}
                                    </span>
                    </div>
                    <div class="col-lg-6 mb-5">
                        <label>{{$t('module_settings.details')}}</label>
                        <textarea v-model="data.details" class="form-control" :class="validation && validation.details ? 'is-invalid' : ''" :placeholder="$t('module_settings.details')"></textarea>
                        <span v-if="validation && validation.details" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.details[0] }}
                                    </span>
                    </div>
                    <div class="col-lg-6 mb-5">
                        <label>{{$t('module_settings.upload_image')}}</label>
                        <div class="form-group">
                            <upload-file
                                    @file="listenerAttachment"
                                    :inner-id="'image'"
                                    :placeholder="$t('module_settings.upload_image')"
                                    :upload="dir_upload"
                                    :start-link="'base'"
                                    v-bind:valueprop="{name:'image',path:image_url}" v-if="reloadUploadAttachment">
                            </upload-file>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-5">
                        <label>&ensp;</label>
                        <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                    </div>

                </div>
                <div class="form-group row">
                    <div class="col-lg-12 mb-5 mt-5">
                        <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                            <h6 class="mt-2">{{$t('module_settings.multi_images')}}</h6>
                            <button type="button" class="btn btn-primary" @click="addRepeater"><i class="fas fa-plus"></i>{{$t('add_more')}}</button>
                        </div>
                        <!-- <h6 class="card-label mt-10">
                            {{$t('api_configurations.param')}}
                            <span class="text-muted pt-2 font-size-sm d-block"></span>
                        </h6> -->
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">{{$t('module_settings.title')}}</th>
                                <th scope="col">{{$t('module_settings.image')}}</th>
                                <th scope="col">{{$t('module_settings.priority')}}</th>
                                <th scope="col">{{ $t('actions') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in data.images_list" :key="index">
                                <td>
                                    <input type="text" v-model="item.title" class="form-control" :placeholder="$t('module_settings.title')"/>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <upload-file
                                                @file="listenerAttachmentMulti(index, $event)"
                                                :inner-id="'image'+index"
                                                :placeholder="$t('module_settings.upload_image')"
                                                :upload="dir_upload"
                                                :start-link="'base'"
                                                v-bind:valueprop="{name:'image',path:item.image_url}" v-if="reloadUploadAttachment">
                                        </upload-file>
                                    </div>
                                </td>
                                <td>
                                    <select name="priority" v-model="item.priority" class="custom-select">
                                        <option v-for="row in priority_length" :key="row" :value="row">{{row}}</option>
                                    </select>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-link mr-2" @click="removeRepeater(index)"><i class="fas fa-trash text-danger"></i></button>
                                </td>
                            </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    </div>
                </div>
            </div>

        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {

        data() {
            return {
                mainRoute: '/settings/module-sub-actions',
                mainRouteDependency: 'base/dependency',
                dir_upload: 'images',
                idEdit: this.$route.params.id ? this.$route.params.id : '',
                data: {
                    module_id: this.$route.params.module_id ? this.$route.params.module_id : '',
                    title: null,
                    summery: null,
                    details: null,
                    image: null,
                    is_active: false,
                    images_list: [],
                },
                isEditing: false,
                validation: null,
                image_url: null,
                reloadUploadAttachment: true,
                repeater: {id: null, title: null, image:null, image_url: null, priority: null},
                priority_length: 10,
                module_settings: [],
            };
        },

        methods: {
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push({name: 'module-settings-sub-actions.index', params:{module_id: this.data.module_id}});
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            update() {
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push({name: 'module-settings-sub-actions.index', params:{module_id: this.data.module_id}});
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            getData() {
                this.reloadUploadAttachment = false;
                ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
                    this.isEditing = true;
                    this.data = response.data.data;
                    this.image_url = response.data.data.image_url;
                    this.reloadUploadAttachment = true;

                    if (response.data.data.images_list && response.data.data.images_list.length > 0){
                        this.images_list = response.data.data.images_list;
                    }else {
                        this.addRepeater();
                    }
                });
            },
            listenerAttachment(event) {
                if (event) {
                    this.image_url = event.pathDB;
                    this.data.image = event.name;
                }
            },
            listenerAttachmentMulti(index, event) {
                if (event) {
                    this.data.images_list[index].image_url = event.pathDB;
                    this.data.images_list[index].image = event.name;
                }
            },
            addRepeater() {
                this.data.images_list.unshift(this.repeater);
                this.repeater = {id: null, title: null, image:null, image_url: null, priority: null};
            },
            removeRepeater(index) {
                if (this.data.images_list.length > 1)
                    this.data.images_list.splice(index, 1);
            },
            getModuleSettings() {
                ApiService.get(`${this.mainRouteDependency}/module-settings`).then((response) => {
                    this.module_settings = response.data.data;
                });

            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: this.$t("MENU.module_settings"), route:{name: 'module-settings.index'}},
                {title: this.$t("MENU.module_settings_sub_actions"), route:{name: 'module-settings-sub-actions.index', params:{module_id: this.data.module_id}}},
                {title: (this.idEdit ? this.$t('update') : this.$t('create'))}
            ]);
            this.getModuleSettings();
            if (this.idEdit) {
                this.getData();
            }else {
                this.addRepeater();
            }
        },
    };
</script>


